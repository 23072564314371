define(['app', 'siteObj'], function(app, siteObj) {

  const salonInfoHeader = () => {
    const component = {};
    component.app = app;

    const _config = {
      selectors: {
        navIcon: '[data-js-element=salonInfoHeader_navIcon]',
        nav: '[data-js-element=salonInfoHeader_nav]'
      },
      classes: {
        navIconOpenClass: 'salonInfoHeader_navIcon-open',
        navOpenClass: 'salonInfoHeader_nav-open'
      }
    };

    const _init = element => {
      component.element = element;
      component.navIcon = component.element.querySelector(component.config.selectors.navIcon);
      component.nav = component.element.querySelector(component.config.selectors.nav);
      component.attachListeners();
      return component;
    };

    const _attachListeners = () => {
      if (siteObj.siteIsMobile) {
        component.navIcon.addEventListener('click', component.navIconClickedMobile);
      }
    };

    const _navIconClickedMobile = () => {
      app.element.toggleClass(component.config.classes.navIconOpenClass, component.navIcon);
      app.element.toggleClass(component.config.classes.navOpenClass, component.nav);
    };

    component.config = _config;
    component.init = _init;
    component.attachListeners = _attachListeners;
    component.navIconClickedMobile = _navIconClickedMobile;

    return component;
  };

  return salonInfoHeader;
});
